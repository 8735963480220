import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Typography,
  Grid,
} from "@mui/material";

const RegistrationModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    country: "",
    dob: "",
    category: "",
    grade: "",
  });

  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error when user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? "" : prevErrors[name],
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async () => {
    if (!validateForm()) {
      return;
    }

    const apiUrl = "https://xceledu.ai/register/global";
    const token = "your_authorization_token_here"; // Replace with actual token

    // try {
    //   await axios.post(apiUrl, formData, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //   });

    setSnackbarMessage("Please proceed to payment");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);

    onClose();
    // } catch (error) {
    //   console.error("Registration Error:", error);
    //   setSnackbarMessage("Registration Failed");
    //   setSnackbarSeverity("error");
    //   setSnackbarOpen(true);
    // }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" mb={2} textAlign="center">
            Register
          </Typography>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              {/* Country */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.country}>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <MenuItem value="USA">USA</MenuItem>
                    <MenuItem value="Canada">Canada</MenuItem>
                    <MenuItem value="Indonesia">Indonesia</MenuItem>
                    <MenuItem value="Azerbaijan">Azerbaijan</MenuItem>
                    <MenuItem value="Uzbekistan">Uzbekistan</MenuItem>
                    <MenuItem value="Bulgaria">Bulgaria</MenuItem>
                    <MenuItem value="Kosovo">Kosovo</MenuItem>
                    <MenuItem value="Georgia">Georgia</MenuItem>
                    <MenuItem value="Kazakhstan">Kazakhstan</MenuItem>
                    <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                    <MenuItem value="Pakistan">Pakistan</MenuItem>
                    <MenuItem value="Mexico">Mexico</MenuItem>
                    <MenuItem value="Turkey">Turkey</MenuItem>
                    <MenuItem value="Kyrgyzstan">Kyrgyzstan</MenuItem>
                    <MenuItem value="Croatia">Croatia</MenuItem>
                    <MenuItem value="Albania">Albania</MenuItem>
                  </Select>
                  {errors.country && (
                    <Typography color="error">{errors.country}</Typography>
                  )}
                </FormControl>
              </Grid>

              {/* Date of Birth */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Date of Birth"
                  name="dob"
                  type="date"
                  value={formData.dob}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.dob}
                  helperText={errors.dob}
                />
              </Grid>

              {/* Grade */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Grade"
                  name="grade"
                  type="number"
                  value={formData.grade}
                  onChange={handleChange}
                  error={!!errors.grade}
                  helperText={errors.grade}
                />
              </Grid>

              {/* Category */}
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.category}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <MenuItem value="Math">Math</MenuItem>
                    <MenuItem value="Robotics">Robotics</MenuItem>
                    <MenuItem value="Cogat">Cogat</MenuItem>
                    <MenuItem value="Art">Art</MenuItem>
                  </Select>
                  {errors.category && (
                    <Typography color="error">{errors.category}</Typography>
                  )}
                </FormControl>
              </Grid>

              {/* Register Button - Full Width Below */}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={handleRegister}
                  disabled={Object.values(formData).some(
                    (value) => value.trim() === ""
                  )}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar Positioned at Top-Center */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegistrationModal;
