import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { envData } from "../../envs";
import axios from "axios";
import Logo from "../logo/Logo";
export default function ExamFinishModal({
  finalResult,
  handleCloseModal,
  openModal,
  isMathRoute,
  studentMetaData,
  timeLeft,
  mathOrCogat,
  examSubmitted,
  singleExamType,
  correctAnswers,
}) {
  const examAnswersOject = mathOrCogat === "Math" ? "mathTest" : "cogatTest";
  const getLocalStorageItems = JSON.parse(
    localStorage.getItem(examAnswersOject)
  );
  const handleExamSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student/finish-exam`,
        {
          examId: singleExamType.examId,
          testResults: getLocalStorageItems,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        handleCloseModal(); //submitted
      }
    } catch (error) {
      console.error("Error posting to APIs:", error);
    }
  };
  const examSubmitHandler = () => {
    examSubmitted();
    handleExamSubmit();
  };
  useEffect(() => {
    // Open dialog when timer reaches 0
    if (timeLeft === 0 && !isMathRoute) {
      // Close dialog after 5 seconds and call API
      setTimeout(() => {
        examSubmitHandler(); // Call submission API
      }, 5000);
    }
  }, [timeLeft]);
  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            padding: "0",
            backgroundColor: "#52668C",
            color: "white",
            textAlign: "center",
          }}
          // sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <div
            // className="footer-logo"
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Logo
              image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
            />
            <Typography style={{ fontSize: "20px" }}>
              {isMathRoute
                ? "Your practice test result, if you want, you can retake"
                : timeLeft
                ? `Are you sure you want to submit your ${mathOrCogat} results?`
                : `Your time is up. Thanks for taking ${mathOrCogat} test!.`}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography
              style={{
                padding: "10px 25px",
                textAlign: "center",
                margin: "10px",
              }}
            >
              {isMathRoute
                ? finalResult
                : timeLeft
                ? "Once you submit, the exam will be over, and no further changes can be made."
                : "See you next time."}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isMathRoute && (
            <Button
              style={{
                background: "#52668C",
                color: "white",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
              onClick={examSubmitHandler}
              disabled={!timeLeft}
            >
              Yes, Submit !
            </Button>
          )}
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
