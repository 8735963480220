import React, { useEffect } from "react";
import AOS from "aos";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import NavScrollTop from "../components/NavScrollTop.jsx";
import { data } from "./resultsData.js";
import { cogatData } from "./resultsData.js";
import ResultsComponent from "./resultsComponent.js";

import cogat1 from "./cogat1.jpg";
import cogat2 from "./cogat2.jpg";
import cogat3 from "./cogat3.jpg";
import cogat4 from "./cogat4.jpg";

import rob1 from "./rob1.jpg";
import rob2 from "./rob2.jpg";
import rob3 from "./rob3.jpg";
import rob4 from "./rob4.jpg";
import rob5 from "./rob5.jpg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FirstLocalRoundResults = () => {
  // this code is used to read csv files and renders as a table

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <React.Fragment>
      <NavScrollTop>
        <SEO title="Xcel || Results" />
        <Header />
        <Breadcrumb
          image="images/bg/xcelpattern3.png"
          title="Math, Robotics and Cogat Results"
          content="Home"
          contentTwo="Results"
          backgroundProp="cover"
        />
        {data.map((results) => (
          <ResultsComponent key={results.subject} resultsData={results} />
        ))}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontWeight: "bold",
            }}
          >
            <h1
              style={{
                width: "70%",
                margin: "auto",
                display: "block",
                textAlign: "center",
              }}
            >
              CogAtXCEL
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            <img style={{ margin: "0" }} src={cogat1} alt="" />
            <img style={{ margin: "0" }} src={cogat2} alt="" />
            <img style={{ margin: "0" }} src={cogat3} alt="" />
            <img style={{ margin: "0" }} src={cogat4} alt="" />
          </AccordionDetails>
        </Accordion>
        {cogatData.map((results) => (
          <ResultsComponent key={results.subject} resultsData={results} />
        ))}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontWeight: "bold",
            }}
          >
            <h1
              style={{
                width: "70%",
                margin: "auto",
                display: "block",
                textAlign: "center",
              }}
            >
              CogAtXCEL Opportunity Round
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            <img style={{ margin: "0" }} src={cogat12} alt="" />
            <img style={{ margin: "0" }} src={cogat13} alt="" />
          </AccordionDetails>
        </Accordion> */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontWeight: "bold",
            }}
          >
            <h1
              style={{
                width: "70%",
                margin: "auto",
                display: "block",
                textAlign: "center",
              }}
            >
              RobotiXCEL
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            <img style={{ margin: "0" }} src={rob1} alt="" />
            <img style={{ margin: "0" }} src={rob2} alt="" />
            <img style={{ margin: "0" }} src={rob3} alt="" />
            <img style={{ margin: "0" }} src={rob4} alt="" />
            <img style={{ margin: "0" }} src={rob5} alt="" />
          </AccordionDetails>
        </Accordion>

        <Footer />
        <ScrollToTop />
      </NavScrollTop>
    </React.Fragment>
  );
};

export default FirstLocalRoundResults;
