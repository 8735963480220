import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { envData } from "./envs";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "react-select";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Form,
  redirect,
  useFetcher,
  useRouteLoaderData,
  Link,
  useNavigate,
} from "react-router-dom";

import "./RegistrationSwitch.css";

export function SignupPage() {
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();
  const [countryList, setCountry] = useState([]);
  const [errorMessage, setErrorMessages] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    schoolCity: "",
    schoolName: "",
    password: "",
    repeatPassword: "",
    gender: "",
    dob: "",
    grade: "",
    country: "",
    agentCode: "",
    secondaryEmail: "",
  });
  const [userRegistrationState, setUserRegistrationState] = useState(true);
  const handleChange = (e) => {
    setUserRegistrationState(!e.target.checked);
  };

  const onClickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    fetch(`${envData.baseApiUrl}/v1/info/coutries`)
      .then((res) => res.json())
      .then((data) => {
        const countryList = data.map(({ countryName, ...rest }) => ({
          label: countryName,
          ...rest,
        }));
        setCountry(countryList.sort((a, b) => a.label.localeCompare(b.label)));
        setLoading(true);
      });
  }, []);
  const handleRegister = (e) => {
    if (e.countryCode && e.label) {
      setUserData({
        ...userData,
        country: e,
      });
      return;
    }
    if (!e.target) {
      let date = e; // value from your state
      let formattedDate = dayjs(date.$d).format("YYYY-DD-MM");
      setUserData({
        ...userData,
        dob: formattedDate.split("-").reverse().join("/"),
      });
      return;
    }

    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    if ((userData.grade < 3 || userData.grade > 12) && userRegistrationState) {
      setErrorMessages("Make sure grades are between 3-12");
      onClickHandler();
      return;
    }
    // const rawData = { ...userData, country: userData.country.label };
    if (userRegistrationState) {
      for (let i in userData) {
        if (!userData[i] && i !== "secondaryEmail") {
          if (i !== "agentCode") {
            setErrorMessages("Please fill out all required fields");
            onClickHandler();
            return;
          }
        }
      }
    }
    if (!userRegistrationState) {
      for (let i in userData) {
        if (!userData[i] && i !== "grade") {
          if (i !== "agentCode") {
            setErrorMessages("Please fill out all required fields");
            onClickHandler();
            return;
          }
        }
      }
    }

    if (userData.password && userData.password !== userData.repeatPassword) {
      return;
    }
    if (!userRegistrationState) {
      try {
        const response = await axios.post(
          `${envData.baseApiUrl}/v1/users/user/register`,
          {
            ...userData,
            country: userData.country.countryCode,
          }
        );
        if (response.status === 200 && response.data.errors) {
          setErrorMessages(response.data.errors);
          onClickHandler();
          return;
        }
        if (response.data?.msg !== "User has been created") {
          setErrorMessages(response.data.msg);
          onClickHandler();
          return;
        }
        setResponse(response.data); // Set response data to state
        navigate("/login");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    } else {
      try {
        const response = await axios.post(
          `${envData.baseApiUrl}/v1/students/student/register`,
          { ...userData, country: userData.country.countryCode }
        );
        if (response.status === 200 && response.data.errors) {
          setErrorMessages(response.data.errors);
          onClickHandler();
          return;
        }
        if (response.data?.msg !== "User has been created") {
          setErrorMessages(response.data.msg);
          onClickHandler();
          return;
        }
        setResponse(response.data); // Set response data to state
        navigate("/login");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };
  return (
    <>
      {/* <Spinner /> */}
      {loading && (
        <div className="container-fluid sign-up">
          <div
            className="row h-100 align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
              <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "bold", color: "black", opacity: "0.5" }}
                  >
                    Student
                  </Typography>
                  <label className="switch">
                    <input type="checkbox" onChange={handleChange} />
                    <span className="slider round"></span>
                  </label>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "black",
                      opacity: "0.5",
                    }}
                  >
                    <>
                      Teacher <br />
                      Supervisor{" "}
                    </>
                  </Typography>
                </div>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "black",
                    opacity: "0.5",
                  }}
                >
                  Register as{" "}
                  {userRegistrationState ? "a Student" : "a Teacher/Supervisor"}
                </Typography>
                <hr
                  style={{
                    border: "2px solid grey",
                    bordeRadius: "5px",
                    marginTop: "5px",
                  }}
                />
                <Typography style={{ color: "crimson", textAlign: "center" }}>
                  {typeof errorMessage === "string" && errorMessage ? (
                    <Alert severity="error">{errorMessage}.</Alert>
                  ) : (
                    ""
                  )}
                </Typography>
                <ul>
                  {Array.isArray(errorMessage) &&
                    errorMessage.map((error, ind) => {
                      if (ind % 2 === 0) {
                        return (
                          <Alert key={ind} severity="error">
                            {" "}
                            {error.msg + " for " + error.path}.
                          </Alert>
                        );
                      }
                    })}
                </ul>
                <Form method="post" replace>
                  <div className="form-floating mb-3">
                    <input
                      value={userData.email}
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleRegister}
                    />
                    <label htmlFor="email">Email address</label>
                  </div>
                  {!userRegistrationState && (
                    <div className="form-floating mb-3">
                      <input
                        value={userData.secondaryEmail}
                        type="email"
                        className="form-control"
                        name="secondaryEmail"
                        onChange={handleRegister}
                      />
                      <label htmlFor="email">Secondary email address</label>
                    </div>
                  )}
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={userData.firstName}
                      onChange={handleRegister}
                    />
                    <label htmlFor="firstName">First Name</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      value={userData.lastName}
                      type="text"
                      className="form-control"
                      name="lastName"
                      onChange={handleRegister}
                    />
                    <label htmlFor="lastName">Last Name</label>
                  </div>
                  <div className="form-floating mb-4">
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="gender"
                        value={userData.gender}
                        onChange={handleRegister}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {userRegistrationState && (
                    <div className="form-floating mb-4">
                      <input
                        min="3"
                        max="12"
                        placeholder="3 - 12"
                        value={userData.grade}
                        type="number"
                        className="form-control"
                        name="grade"
                        onChange={handleRegister}
                      />
                      <label htmlFor="grade">Grade/Class</label>
                    </div>
                  )}
                  <div className="form-floating mb-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          name="dob"
                          label="Date of birth"
                          // value={userData.dob}
                          onChange={handleRegister}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div
                    className="form-floating mb-4"
                    // style={{ pointerEvents: "none" }}
                  >
                    <Select
                      options={countryList}
                      value={userData.country}
                      onChange={handleRegister}
                    />
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      value={userData.phone}
                      type="text"
                      className="form-control"
                      name="phone"
                      required
                      onChange={handleRegister}
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      name="schoolName"
                      value={userData.schoolName}
                      onChange={handleRegister}
                    />
                    <label htmlFor="schoolName">School Name</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      name="schoolCity"
                      value={userData.schoolCity}
                      onChange={handleRegister}
                    />
                    <label htmlFor="schoolCity">School City</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={userData.password}
                      onChange={handleRegister}
                    />
                    <label htmlFor="password">Password</label>
                    <FormHelperText id="component-helper-text">
                      Password must contain at least:
                      <br />5 characters <br /> 1 Uppercase <br /> 1 Number{" "}
                      <br />1 Symbol
                    </FormHelperText>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      style={{
                        border:
                          userData.password !== userData.repeatPassword
                            ? "1px solid red"
                            : "",
                      }}
                      type="password"
                      className="form-control"
                      name="repeatPassword"
                      value={userData.repeatPassword}
                      onChange={handleRegister}
                    />
                    <label htmlFor="repeatPassword">Repeat Password</label>
                  </div>

                  {userRegistrationState && (
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="agentCode"
                        value={userData.agentCode}
                        onChange={handleRegister}
                      />
                      <label htmlFor="schoolCity">
                        Supervisor/Teacher ID. Skip if not
                      </label>
                    </div>
                  )}

                  <button
                    type="submit"
                    className="btn btn-primary py-3 w-100 mb-4"
                    onClick={handleSubmitRegister}
                  >
                    Register
                  </button>
                </Form>
                <p className="text-center mb-0">
                  Already have an Account?{" "}
                  <Link to="/login">
                    {" "}
                    <span className="account-sign-in">Sign In</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// export async function signupAction({ request }) {
//     let formData = await request.formData();
//     const username = formData.get('email');
//     if (!username) {
//         return {
//             error: 'You must provide an email to log in',
//         };
//     }
//     try {
//         // await fakeAuthProvider.signin(username);//Chabnge
//     } catch (error) {
//         return {
//             error: 'Invalid login attempt',
//         };
//     }
//     return redirect('/login?');
// }

// export async function loginAction({ request }) {
//     let formData = await request.formData();
//     const username = formData.get('email');
//     const checked = formData.get('iAmStudent');
//     const pw = formData.get('password');
//     if (!username) {
//         return {
//             error: 'You must provide an email to log in',
//         };
//     }
//     if (!pw) {
//         return {
//             error: 'You must provide password to log in',
//         };
//     }

//     try {
//         await fakeAuthProvider.signin(username);
//     } catch (error) {
//         return {
//             error: 'Invalid login attempt',
//         };
//     }

//     let redirectTo = formData.get('redirectTo');
//     return redirect(redirectTo || '/');
// }

// export async function loginLoader() {
//     if (fakeAuthProvider.isAuthenticated) {
//         return redirect('/dash');
//     }
//     return null;
// }

export function Spinner() {
  return (
    <div
      id="spinner"
      className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-border text-primary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export async function protectedLoader({ request }) {
  fakeAuthProvider.isAuthenticated = false;
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${envData.baseApiUrl}/v1/users/user/token`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.data.valid) {
      fakeAuthProvider.isAuthenticated = true;
    }
  } catch (error) {
    console.error(error);
  }

  if (!fakeAuthProvider.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const userDetailedInfo = fakeAuthProvider.getData();
  return {
    data: {
      username:
        fakeAuthProvider.getFullname() && fakeAuthProvider.getData().name,
      type: "Admin",
      fullName:
        fakeAuthProvider.getFullname() && fakeAuthProvider.getFullname(),
      img:
        userDetailedInfo.type === "I" ||
        userDetailedInfo.type === "P" ||
        userDetailedInfo.type === "A"
          ? `https://cdn-icons-png.freepik.com/256/8276/8276839.png?semt=ais_hybrid`
          : `https://cdn-icons-png.flaticon.com/512/535/535521.png`,
    },
    sidebar: [
      // { name: "Home", path: "/dash", icon: "tachometer", type: "Admin" },
      {
        name:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "Dashboard"
            : "My Exams",
        path: "/dash",
        icon: "tachometer",
        type: "Admin",
      },
      {
        name:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "Robotics - Art"
            : "News",
        path:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "/dash/robotics-art"
            : "/dash/news",
        icon: "tachometer",
        type: "Admin",
      },
      {
        name:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "Student List"
            : "Student Info",
        path:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "/dash/student-list"
            : "/dash/student",
        icon: "address-card",
        type: "Admin",
      },
      {
        name:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "Profile"
            : "Results",
        path:
          userDetailedInfo.type === "I" ||
          userDetailedInfo.type === "P" ||
          userDetailedInfo.type === "A"
            ? "/dash/profile-info"
            : "/dash/results",
        icon: "note",
        type: "Admin",
      },
      {
        name: userDetailedInfo.roboticsExist ? "Robotics" : "",
        path: userDetailedInfo.roboticsExist ? "/dash/robotics" : "",
        icon: userDetailedInfo.roboticsExist ? "robot" : "",
        type: userDetailedInfo.roboticsExist ? "Admin" : "",
      },
      {
        name: userDetailedInfo.artExist ? "Art" : "",
        path: userDetailedInfo.artExist ? "/dash/art" : "",
        icon: userDetailedInfo.artExist ? "edit" : "",
        type: userDetailedInfo.artExist ? "Admin" : "",
      },
      {
        name: userDetailedInfo.mathExist ? "Math" : "",
        path: userDetailedInfo.mathExist ? "/dash/math" : "",
        icon: userDetailedInfo.mathExist ? "solid" : "",
        type: userDetailedInfo.mathExist ? "Admin" : "",
      },
      {
        name: userDetailedInfo.cogatExist ? "CogAT" : "",
        path: userDetailedInfo.cogatExist ? "/dash/cogat" : "",
        icon: userDetailedInfo.cogatExist ? "atom" : "",
        type: userDetailedInfo.cogatExist ? "Admin" : "",
      },
      {
        name: "Practice Math",
        path: "/dash/practiceMath",
        icon: "solid",
        type: "Admin",
      },
      {
        name: "Practice CogAT",
        path: "/dash/practiceCogat",
        icon: "atom",
        type: "Admin",
      },
    ],
  };
}
export function useGetUserContext() {
  let data = useRouteLoaderData("dash");
  return data;
}

export function SignoutButton() {
  let fetcher = useFetcher();
  let isLoggingOut = fetcher.formData != null;
  return (
    <fetcher.Form method="post" action="/logout">
      <button
        type="submit"
        disabled={isLoggingOut}
        style={{ marginLeft: "15px" }}
      >
        {isLoggingOut ? "Signing out..." : "Sign out"}
      </button>
    </fetcher.Form>
  );
}

export const fakeAuthProvider = {
  // isAuthenticated: true,
  // username: 'null',
  isAuthenticated: false,
  username: null,

  // async validateToken() {
  //     const token = localStorage.getItem('token')
  //     const response = await axios.get('https://dev-api.xceledu.ai/v1/users/user/token', { headers: { Authorization: `Bearer ${token}` } })
  //     if (response.data.valid) {
  //         console.log('true or noooooooooot')
  //         fakeAuthProvider.isAuthenticated = true
  //         // return true

  //     }
  // },

  errorMessageForLogin(response, userData) {
    if (
      response.status === 200 &&
      response.data.msg !== "Wrong credentials" &&
      response.data.msg !== "Username or password is incorrect"
    ) {
      localStorage.setItem("token", response.data.msg);
      fakeAuthProvider.isAuthenticated = true;
      fakeAuthProvider.username = userData.email;
      return true;
    }
    if (response.status === 200 && response.data?.msg === "Wrong credentials") {
      return false;
    }
  },

  async signin(userData) {
    if (userData.isInstructor) {
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/users/user/login`,
        userData
      );
      return this.errorMessageForLogin(response, userData);
    } else {
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student/login`,
        userData
      );
      return this.errorMessageForLogin(response, userData);
    }
  },
  signout() {
    // await new Promise(r => setTimeout(r, 500)); // fake delay
    localStorage.removeItem("token");
    fakeAuthProvider.isAuthenticated = false;
    fakeAuthProvider.username = "";
  },
  getToken() {
    const token = localStorage.getItem("token");
    return token && localStorage.getItem("token");
  },
  getData() {
    const token = localStorage.getItem("token");
    if (token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    }
    return null;
  },

  isExprired() {
    const data = this.getData();
    if (!data) return true;
    const dateNow = new Date();
    if (data.exp < dateNow.getTime()) return false;
    return true;
  },
  getFullname() {
    const data = this.getData() && this.getData();
    return (
      this.getData() &&
      data.firstname.slice(0, 1).toUpperCase() +
        data.firstname.slice(1) +
        " " +
        data.lastname.slice(0, 1).toUpperCase() +
        data.lastname.slice(1)
    );
  },
};

function getHash(str) {
  let result = "";
  let len = str.length - 1;
  while (0 < len) {
    if (len <= 0) len = 0;
    result += str[len];
    len -= 2;
  }
  return result.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}
