import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Papa from "papaparse";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ResultsComponent = ({ resultsData }) => {
  // this code is used to read csv files and renders as a table
  const [jsonData, setJsonData] = useState([]);
  useEffect(() => {
    // Fetch CSV file from public/assets folder
    fetch(resultsData.results, { headers: { "Content-Type": "text/csv" } })
      .then((response) => response.text())
      .then((csvText) => {
        // console.log("CSV Content:", csvText); // Debugging: Check if CSV is valid
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            setJsonData(result.data);
          },
        });
      })
      .catch((error) => console.error("Error fetching CSV:", error));
  }, []);

  const awardPriority = { GOLD: 1, SILVER: 2, BRONZE: 3 };

  const sortedData = [...jsonData].sort((a, b) => {
    if (a.GRADE !== b.GRADE) return a.GRADE - b.GRADE;
    return (awardPriority[a.AWARD] || 4) - (awardPriority[b.AWARD] || 4);
  });
  const getRowColor = (award) => {
    switch (award) {
      case "GOLD":
        return "#FFD700";
      case "SILVER":
        return "#C0C0C0";
      case "BRONZE":
        return "#CD7F32";
      default:
        return "#A2C3C8"; // Bright Teal for any other award
    }
  };
  const columnHeader = ["Country", "Student Name", "Grade", "School", "Award"];
  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            fontWeight: "bold",
          }}
        >
          <h1
            style={{
              width: "70%",
              margin: "auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {resultsData.subject}
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          {jsonData.length > 0 && (
            <TableContainer
              component={Paper}
              style={{ padding: "16px 36px 10px 10px", margin: "16px" }}
            >
              <Table>
                <TableHead style={{ background: "teal", color: "white" }}>
                  <TableRow>
                    {columnHeader.map((column) => (
                      <TableCell
                        key={column}
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid white",
                        }}
                      >
                        {column.toUpperCase()}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{ backgroundColor: getRowColor(row.AWARD) }}
                    >
                      <TableCell>
                        {row.COUNTRY ? row.COUNTRY.toUpperCase() : "."}
                      </TableCell>
                      <TableCell>
                        {row["STUDENT NAME"]
                          ? row["STUDENT NAME"].toUpperCase()
                          : row["STUDENT NAME"]}
                      </TableCell>
                      <TableCell>{row.GRADE.toUpperCase()}</TableCell>
                      <TableCell>
                        {row.SCHOOL ? row.SCHOOL.toUpperCase() : "."}
                      </TableCell>
                      <TableCell>{row.AWARD}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default ResultsComponent;
