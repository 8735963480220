import * as React from "react";
import Box from "@mui/material/Box";

import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import Header from "./header";
import NewsList from "./newsList";

export default function StudentNewsPage() {
  return (
    <div>
      <Header />
      <NewsList />
    </div>
  );
}
