import { useEffect, useState } from "react";
import { envData } from "../../envs";
import Button from "@mui/material/Button";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { isMobile } from "react-device-detect";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { CSVLink, CSVDownload } from "react-csv";
import { styled } from "@mui/material/styles";

import IosShareIcon from "@mui/icons-material/IosShare";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import LaunchIcon from "@mui/icons-material/Launch";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#001F3F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

import axios from "axios";
function RoboticsStudentsTable() {
  const [roboticsStudents, setRoboticsStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [singleRobotProjectData, setRoboticsStudentData] = useState({});
  const [page, setPage] = useState(0);
  function filterPaidRoboticsExams(data) {
    return data.filter(
      (person) =>
        Array.isArray(person.exams) &&
        person.exams.some(
          (exam) => exam.examName === "Robotics" && exam.isPayed
        )
    );
  }

  const getStudentInfo = async () => {
    const token = localStorage.getItem("token");
    try {
      const request1 = await axios.get(`${envData.baseApiUrl}/v1/students`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      //   console.log(filterPaidRoboticsExams(request2.data), "???????????????/");
      const roboticsFiltered = filterPaidRoboticsExams(request1.data);

      setRoboticsStudents(roboticsFiltered);
      const request2 = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/team/list?email=${roboticsFiltered[page].email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRoboticsStudentData(request2.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  function filterObjectProperties(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) => typeof value === "object" && value !== null
      )
    );
  }
  const getRoboticsProjects = async () => {
    const token = localStorage.getItem("token");
    try {
      const request2 = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/team/list?email=${roboticsStudents[page].email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRoboticsStudentData(request2.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getStudentInfo();
  }, []);
  useEffect(() => {
    getRoboticsProjects();
  }, [page]);
  const handleNextPage = () => {
    if (page < roboticsStudents.length - 1) {
      setPage(page + 1);
    }
  };
  const handlePrevPage = () => {
    if (page) {
      setPage(page - 1);
    }
  };
  const filteredAfterSearch = roboticsStudents.filter((student) =>
    student?.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        width: "90%",
        flexDirection: "column",
      }}
    >
      {Object.keys(singleRobotProjectData).length > 0 &&
      typeof singleRobotProjectData.msg !== "string" ? (
        <div style={{ color: "black" }}>
          <h4>
            {singleRobotProjectData?.msg?.members[0]?.firstName +
              " " +
              singleRobotProjectData?.msg?.members[0]?.lastName +
              "- Category: " +
              (singleRobotProjectData?.msg?.category &&
                singleRobotProjectData?.msg?.category.toUpperCase())}
          </h4>
          <h6>Team Info: </h6>
          <p>Team Members: {singleRobotProjectData?.msg?.teamMembers}</p>
          <p>Team Name: {singleRobotProjectData?.msg?.teamName}</p>
          <p>
            Project video:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={singleRobotProjectData?.msg?.url}
              style={{ textDecoration: "underline", color: "teal" }}
            >
              Click to open
            </a>
          </p>
          <p>
            File uploaded: {singleRobotProjectData?.msg?.fileUrl ? "YES" : "NO"}{" "}
          </p>
        </div>
      ) : typeof singleRobotProjectData?.msg === "string" ? (
        <div>No data for this student, Click next button</div>
      ) : (
        <>Loading</>
      )}
      <div>
        <br />
        <br />
        <br />
      </div>
      {/* {JSON.stringify(singleRobotProjectData)} */}
      <div style={{ display: "flex" }}>
        <Button
          variant="contained"
          onClick={handlePrevPage}
          style={{ marginRight: "10px" }}
          disabled={!page}
        >
          prev
        </Button>
        <Button variant="contained" onClick={handleNextPage}>
          {roboticsStudents.length - 1 === page ? "End of data" : "next"}
        </Button>
      </div>
      <input
        style={{ width: isMobile ? "90%" : "50%", margin: "10px 0px" }}
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "5px" }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ border: "3px solid red" }}>
              <TableRow style={{ border: "3px solid red" }}>
                <StyledTableCell
                  style={{
                    minWidth: 30,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  #
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    minWidth: 170,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Full Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    minWidth: 130,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Country
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 140,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 140,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Team Members
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 20,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Grade
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 140,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  School Name
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  DOB
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 110,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Phone
                </StyledTableCell>
                {/* <StyledTableCell
                        //   onClick={() => handleSort("registeredSubjects")}
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Registered Exams
                      </StyledTableCell> */}
                {/* <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Time Taken
                      </StyledTableCell> */}
                {/* <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Cogat Correct Answers
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        onClick={() => handleSort("cogatScore")}
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        {sortConfig.key === "cogatScore" &&
                        sortConfig.direction === "desc" ? (
                          <ArrowCircleDownIcon />
                        ) : (
                          <ArrowCircleUpIcon />
                        )}
                        Cogat Total Score
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Math Correct Answers
                      </StyledTableCell>

                      <StyledTableCell
                        align="right"
                        onClick={() => handleSort("mathScore")}
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        {sortConfig.key === "mathScore" &&
                        sortConfig.direction === "desc" ? (
                          <ArrowCircleDownIcon />
                        ) : (
                          <ArrowCircleUpIcon />
                        )}
                        Math Total Score
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        Completion Status
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        Certificate
                      </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAfterSearch.map((row, ind) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.email}
                  >
                    <StyledTableCell>{ind + 1}</StyledTableCell>
                    <StyledTableCell>
                      {row.firstName + " " + row.lastName}
                    </StyledTableCell>

                    <StyledTableCell>{row.countryName}</StyledTableCell>
                    <StyledTableCell align="right">{row.email}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.teamMembers}
                    </StyledTableCell>

                    <StyledTableCell align="right">{row.grade}</StyledTableCell>

                    <StyledTableCell align="right">
                      {row.schoolName}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.dob}</StyledTableCell>
                    <StyledTableCell align="right">{row.phone}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default RoboticsStudentsTable;
