import febJanMathALL from "./febJanMathALL.csv";
import finalCogat from "./finalCogat.csv";
import firstRoundMath from "./firstRoundMath.csv";
import art2025 from "./art2025.csv";

export const data = [
  {
    subject: "MathXCEL",
    results: firstRoundMath,
  },
  {
    subject: "MathXCEL Opportunity Round",
    results: febJanMathALL,
  },
  {
    subject: "ArtXCEL",
    results: art2025,
  },
];
export const cogatData = [
  {
    subject: "CogatXCEL Opportunity Round",
    results: finalCogat,
  },
];
