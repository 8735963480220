import React from "react";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const RoboticsArtPAges = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 4 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/dash/robotics-list")}
      >
        Robotics Table
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/dash/art-list")}
      >
        Art Table
      </Button>
    </Box>
  );
};

export default RoboticsArtPAges;
