import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// Styled wrapper for each article
const ArticleWrapper = styled("div")`
  background-color: #f4f4f4;
  padding: 20px;
  margin: 15px 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Article component
function Article({ title, date, content }) {
  return (
    <ArticleWrapper>
      <Typography variant="h5" component="h2" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {date}
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {content}
      </Typography>
    </ArticleWrapper>
  );
}

export default Article;
