import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// Create a styled Header using MUI's `styled`
const HeaderWrapper = styled("header")`
  background-color: #001f3f;
  color: white;
  padding: 20px;
  text-align: center;
`;

function Header() {
  return (
    <HeaderWrapper>
      <Typography variant="h3" component="h1" style={{ color: "white" }}>
        Student News
      </Typography>
    </HeaderWrapper>
  );
}

export default Header;
