import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { envData } from "../../envs";
import { isMobile } from "react-device-detect";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { fakeAuthProvider } from "../../LoginActions";
import Alert from "@mui/material/Alert";
import { CSVLink, CSVDownload } from "react-csv";
import IosShareIcon from "@mui/icons-material/IosShare";
import StudentUploadPage from "../Instructor/StudentUploadPage";
import { decrypt } from "./answerEncryptDecrypt";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import LaunchIcon from "@mui/icons-material/Launch";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#001F3F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const TableComponent = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [schoolFilter, setSchoolFilter] = useState("All Schools");
  const [countryFilter, setCountryFilter] = useState("All Countries");
  const [gradeFilter, setGradeFilter] = useState("All Grades");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [registeredFilter, setRegisteredFilter] = useState("registered");
  const [completedFilter, setCompletedFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState("All");
  const [roboticsFilter, setRoboticsFilter] = useState("All");
  function containsNumber(str) {
    return /\d/.test(str);
  }
  const Spinner = () => (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <CircularProgress />
    </div>
  );

  //pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Check if any exam is completed
  const isStudentCompleted = (exams) => {
    return exams.some((exam) => exam.isExamCompleted);
  };

  // Calculate time taken for the exam
  const calculateTimeTaken = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diffInSeconds = (endTime - startTime) / 1000;
    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = Math.floor(diffInSeconds % 60);
    const time = `${minutes}m ${seconds}s`;
    return parseInt(time, 10) > 82 ? "80m 55s" : time;
  };
  // Calculate correct answers and score for a specific subject
  const calculateSubjectResults = (exams, subject) => {
    const subjectExam = exams.find(
      (exam) => exam.examName.toLowerCase() === subject.toLowerCase()
    );
    if (!subjectExam || !subjectExam.isExamCompleted) {
      return { correctAnswers: 0, totalScore: 0 };
    }

    let correctAnswers = 0;
    let totalScore = 0;

    subjectExam.questions.forEach((question) => {
      //   console.log(decrypt(question.answer, "2023"), question.blob_id);
      if (
        subjectExam.answeredByStudent[question.blob_id] ===
        decrypt(question.answer, 2023)
      ) {
        correctAnswers++;
        if (subject === "Math") {
          totalScore += parseInt(question.weight, 10);
        } else {
          totalScore += 2;
        }
        // totalScore += subject === "Math" ? parseInt(question.weight, 10) : 2; // Multiply weight for score
      }
    });
    return { correctAnswers, totalScore };
  };
  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const sortedData = [...students].sort((a, b) => {
    if (!sortConfig.key) return 0;

    let aValue;
    let bValue;

    if (sortConfig.key === "cogatScore") {
      aValue = calculateSubjectResults(a.exams, "CogAt").totalScore;
      bValue = calculateSubjectResults(b.exams, "CogAt").totalScore;
    } else if (sortConfig.key === "mathScore") {
      aValue = calculateSubjectResults(a.exams, "Math").totalScore;
      bValue = calculateSubjectResults(b.exams, "Math").totalScore;
    }
    // else if (sortConfig.key === "registeredSubjects") {
    //   aValue = a.exams.length;
    //   bValue = b.exams.length;
    // } else if (sortConfig.key === "grade") {
    //   aValue = a.grade;
    //   bValue = b.grade;
    // }
    else {
      aValue = a[sortConfig.key];
      bValue = b[sortConfig.key];
    }

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  //date treshold
  const thresholdDate1 = new Date("2025-01-20");
  const thresholdDate2 = new Date("2025-02-10");

  const dateFilteredData = sortedData.filter((student) => {
    return student.exams.some((exam) => {
      const sessionEnd = new Date(exam.sessionEndTS);

      if (dateFilter === "Between Jan 20 - Feb 10") {
        return sessionEnd >= thresholdDate1 && sessionEnd <= thresholdDate2;
      } else if (dateFilter === "After Feb 10") {
        return sessionEnd > thresholdDate2;
      }
      return true; // Default case (All Dates)
    });
  });
  // Handle filtering by school and country
  const filteredData = sortedData.filter((student) => {
    const matchesName = student.firstName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSchool =
      schoolFilter === "All Schools" || student.schoolName === schoolFilter;
    const matchesCountry =
      countryFilter === "All Countries" ||
      student.countryName === countryFilter;
    const matchesGrade =
      gradeFilter === "All Grades" || student.grade === gradeFilter;
    const matchesRegistered =
      registeredFilter === "registered"
        ? student?.exams &&
          student.exams.map((exam) => exam.examName).join(", ")
        : registeredFilter === "NA"
        ? student?.exams &&
          !student.exams.map((exam) => exam.examName).join(", ")
        : true;
    const matchesCompleted =
      completedFilter === "All" ||
      (completedFilter === "Completed" && isStudentCompleted(student.exams)) ||
      (completedFilter === "Not Completed" &&
        !isStudentCompleted(student.exams));
    // Date Filter: Check sessionEndTS for Before/After Jan 20, 2025
    const matchesDate =
      dateFilter === "All" ||
      (dateFilter === "Between Jan 20 - Feb 10" &&
        student.exams.some((exam) => {
          const sessionEnd = new Date(exam.sessionEndTS);
          return sessionEnd >= thresholdDate1 && sessionEnd <= thresholdDate2;
        })) ||
      (dateFilter === "After Feb 10" &&
        student.exams.some((exam) => {
          const sessionEnd = new Date(exam.sessionEndTS);
          return sessionEnd > thresholdDate2;
        }));
    const participatedInRobotics =
      roboticsFilter === "All" ||
      (roboticsFilter === "Robotics Only" &&
        student.exams.some((exam) => exam.examName === "Robotics"));
    return (
      matchesName &&
      matchesSchool &&
      matchesCountry &&
      matchesGrade &&
      matchesRegistered &&
      matchesCompleted &&
      matchesDate &&
      participatedInRobotics
    );
  });

  // Extract unique school names and countries for dropdown filters
  const uniqueSchools = [
    "All Schools",
    ...new Set(students.map((student) => student.schoolName)),
  ];
  const uniqueCountries = [
    "All Countries",
    ...new Set(students.map((student) => student.countryName)),
  ];
  const uniqueGrades = [
    "All Grades",
    ...new Set(
      students.map((student) => student.grade !== "sdfs" && student.grade)
    ),
  ];

  useEffect(() => {
    // Fetch student data with axios
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        const response = await axios.get(`${envData.baseApiUrl}/v1/students`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const numericFilteredStudents = response.data.filter(
          (student) => !containsNumber(student.firstName + student.lastName)
        );
        const filteredStudents = numericFilteredStudents.filter(
          (student) =>
            student.firstName !== "asd" &&
            student.firstName !== "Asd" &&
            student.firstName !== "Kanybek" &&
            student.firstName !== "data.firstName" &&
            student.firstName !== "ASD"
        );

        setStudents(filteredStudents);
      } catch (error) {
        console.error("Error fetching student data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  // console.log(filteredData, "filteredData");
  // const count = filteredData.length;
  // const totalPages = Math.ceil(count / rowsPerPage);

  // // Ensure page is within bounds
  // const currentPage = Math.min(page, totalPages - 1);
  const userDataType =
    fakeAuthProvider.getData().type === "A" ||
    fakeAuthProvider.getData().type === "P";

  const getTopScorers = (subject) => {
    const scores = filteredData.map((student) => ({
      name: student.firstName,
      totalScore: calculateSubjectResults(student.exams, subject).totalScore,
    }));
    return scores
      .sort((a, b) => b.totalScore - a.totalScore)
      .slice(0, 6)
      .map((scorer) => scorer.name);
  };

  const topCogatScorers = getTopScorers("CogAt");
  const topMathScorers = getTopScorers("Math");

  const exportToCSV = () => {
    // CSV Headers
    const headers = [
      "Country",
      "Email",
      "Full Name",
      "School Name",
      "DOB",
      "Grade",
      "Completion Status",
      "Registered Subjects",
      "Time Taken",
      "Cogat Correct Answers",
      "Cogat Total Score",
      "Math Correct Answers",
      "Math Total Score",
    ];

    // Rows
    const rows = filteredData.map((student) => {
      const { correctAnswers: cogatCorrect, totalScore: cogatScore } =
        calculateSubjectResults(student.exams, "CogAt");
      const { correctAnswers: mathCorrect, totalScore: mathScore } =
        calculateSubjectResults(student.exams, "Math");
      const registeredSubjects = student.exams
        .map((exam) => exam.examName)
        .join("/");
      const isCompleted = student.exams.some((exam) => exam.isExamCompleted)
        ? "Completed"
        : "Not Completed";
      const completedExams = student.exams.filter(
        (exam) => exam.isExamCompleted
      );
      // const timeTaken =
      //   completedExams.length > 0
      //     ? calculateTimeTaken(
      //         completedExams[0].sessionStartTS,
      //         completedExams[0].sessionEndTS
      //       )
      //     : "-";
      const finalTimeTaken = calculateTime(completedExams);
      // const finalTimeTaken =
      //   timeTaken !== "-" && parseInt(timeTaken, 10) > 82
      //     ? "80m 55s"
      //     : timeTaken;
      return [
        student.countryName,
        student.email,
        student.firstName + " " + student.lastName,
        student.schoolName,
        student.dob,
        student.grade,
        isCompleted,
        registeredSubjects,
        finalTimeTaken,
        cogatCorrect,
        cogatScore,
        mathCorrect,
        mathScore,
      ];
    });

    // Create CSV Content
    const csvContent =
      headers.join(",") + "\n" + rows.map((row) => row.join(",")).join("\n");

    // Create a Blob and download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "table_data.csv";
    link.click();
  };
  function calculateTime(arr) {
    const time = [];
    if (arr.length > 0) {
      for (let i of arr) {
        time.push(
          `${i.examName} ${calculateTimeTaken(
            i.sessionStartTS,
            i.sessionEndTS
          )}`
        );
      }
    } else {
      time.push("-");
    }
    return time.join("/");
  }
  return (
    <div
      style={{
        marginBottom: "10px",
        paddingBottom: "12px",
      }}
    >
      {userDataType && (
        <StudentUploadPage userType={fakeAuthProvider.getData().type} />
      )}
      <div
        style={{
          margin: "10px auto",
          width: "90%",
        }}
      >
        <Box
          style={{
            display: "flex",
            marginTop: "5px",
            justifyContent: "space-between",
          }}
        >
          <input
            style={{ width: isMobile ? "90%" : "50%" }}
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <Box
            style={{
              marginLeft: "15px",
              textAlign: "center",
            }}
            onClick={exportToCSV}
          >
            <IosShareIcon
              style={{ fontSize: isMobile ? "25px" : "35px", color: "#1292EE" }}
            />
            <br />
            Export to file
          </Box>
        </Box>

        <div>
          <div style={{ marginBottom: "10px" }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
                label="Country"
              >
                {uniqueCountries.map((countryName) => (
                  <MenuItem key={countryName} value={countryName}>
                    {countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                School
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={schoolFilter}
                onChange={(e) => setSchoolFilter(e.target.value)}
                label="School"
              >
                {uniqueSchools.map((schoolName) => (
                  <MenuItem key={schoolName} value={schoolName}>
                    {schoolName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Grade
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={gradeFilter}
                onChange={(e) => setGradeFilter(e.target.value)}
                label="Grade"
              >
                {uniqueGrades.map((grade) => (
                  <MenuItem key={grade} value={grade}>
                    {grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Registered Exams
              </InputLabel>
              <Select
                value={registeredFilter}
                onChange={(e) => setRegisteredFilter(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="registered">Registered</MenuItem>
                <MenuItem value="NA">Not Registered</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Completion Status
              </InputLabel>
              <Select
                value={completedFilter}
                onChange={(e) => setCompletedFilter(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Not Completed">Incomplete</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Date Filter
              </InputLabel>

              <Select
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              >
                <MenuItem value="All">All Dates</MenuItem>
                <MenuItem value="Between Jan 20 - Feb 10">
                  Between Jan 20 - Feb 10, 2025
                </MenuItem>
                <MenuItem value="After Feb 10">After Feb 10, 2025</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Subject select
              </InputLabel>
              <Select
                onChange={(e) => setRoboticsFilter(e.target.value)}
                value={roboticsFilter}
              >
                <MenuItem value="All">All Students</MenuItem>
                <MenuItem value="Robotics Only">Robotics Participants</MenuItem>
              </Select>
            </FormControl>

            <Box>
              <p
                style={{
                  paddingLeft: "10px",
                  fontWeight: "bold",
                  color: "#2E5894",
                }}
              >
                Total filtered: {filteredData.length} students
              </p>
            </Box>
          </div>
          {!filteredData.length && (
            <div>
              <Alert severity="info">
                There is no registered students to show at this time.
              </Alert>
            </div>
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "5px" }}>
              <TableContainer sx={{ maxHeight: 640 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead style={{ border: "3px solid red" }}>
                    <TableRow style={{ border: "3px solid red" }}>
                      <StyledTableCell
                        style={{
                          minWidth: 30,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        #
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => handleSort("name")}
                        style={{
                          minWidth: 170,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Full Name
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => handleSort("countryName")}
                        style={{
                          minWidth: 130,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Country
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 140,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Email
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 20,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Grade
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 140,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        School Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        DOB
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 110,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Phone
                      </StyledTableCell>
                      <StyledTableCell
                        //   onClick={() => handleSort("registeredSubjects")}
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Registered Exams
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Time Taken
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Cogat Correct Answers
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        onClick={() => handleSort("cogatScore")}
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        {sortConfig.key === "cogatScore" &&
                        sortConfig.direction === "desc" ? (
                          <ArrowCircleDownIcon />
                        ) : (
                          <ArrowCircleUpIcon />
                        )}
                        Cogat Total Score
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        Math Correct Answers
                      </StyledTableCell>

                      <StyledTableCell
                        align="right"
                        onClick={() => handleSort("mathScore")}
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        {sortConfig.key === "mathScore" &&
                        sortConfig.direction === "desc" ? (
                          <ArrowCircleDownIcon />
                        ) : (
                          <ArrowCircleUpIcon />
                        )}
                        Math Total Score
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        Completion Status
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{
                          minWidth: 100,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        Certificate
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!filteredData.length &&
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, ind) => {
                          const registeredSubjects = row.exams.map(
                            (exam) => exam.examName
                          );
                          const completedExams = row.exams.filter(
                            (exam) => exam.isExamCompleted
                          );
                          const {
                            correctAnswers: cogatCorrect,
                            totalScore: cogatScore,
                          } = calculateSubjectResults(row.exams, "CogAt");

                          const {
                            correctAnswers: mathCorrect,
                            totalScore: mathScore,
                          } = calculateSubjectResults(row.exams, "Math");

                          // const timeTaken =
                          //   completedExams.length > 0
                          //     ? calculateTimeTaken(
                          //         completedExams[0].sessionStartTS,
                          //         completedExams[0].sessionEndTS
                          //       )
                          //     : "-";

                          const isTopCogatScorer = topCogatScorers.includes(
                            row.firstName
                          );
                          const isTopMathScorer = topMathScorers.includes(
                            row.firstName
                          );
                          const finalTimeTaken = calculateTime(completedExams);

                          return (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.email}
                            >
                              <StyledTableCell>{ind + 1}</StyledTableCell>
                              <StyledTableCell>
                                {row.firstName + " " + row.lastName}
                              </StyledTableCell>

                              <StyledTableCell>
                                {row.countryName}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.email}
                              </StyledTableCell>

                              <StyledTableCell align="right">
                                {row.grade}
                              </StyledTableCell>

                              <StyledTableCell align="right">
                                {row.schoolName}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.dob}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.phone}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {registeredSubjects.length > 0
                                  ? registeredSubjects.join(", ")
                                  : "Unpaid"}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {finalTimeTaken}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {cogatCorrect}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: isTopCogatScorer
                                    ? "gold"
                                    : "transparent",
                                  fontWeight: isTopCogatScorer
                                    ? "bold"
                                    : "normal",
                                }}
                                align="right"
                              >
                                {cogatScore}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {mathCorrect}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: isTopMathScorer
                                    ? "gold"
                                    : "transparent",
                                  fontWeight: isTopMathScorer
                                    ? "bold"
                                    : "normal",
                                }}
                                align="right"
                              >
                                {mathScore}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {isStudentCompleted(row.exams)
                                  ? "Completed"
                                  : "Not Completed"}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {isStudentCompleted(row.exams) ? (
                                  <LaunchIcon />
                                ) : (
                                  "NA"
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
