import React from "react";
import { styled } from "@mui/material/styles";
import Article from "./article";

// Styled container for the news list
const NewsContainer = styled("div")`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
`;

// Updated news data with striking words and event details
const newsData = [
  {
    title: "Global Round: The Ultimate Showdown in LA at UCLA!",
    date: "June 1-4, 2025",
    content:
      "Get ready for the most thrilling Global Round, happening at UCLA, Los Angeles, between June 1-4, 2025! This event will bring together students from around the world in an unforgettable competition. Mark your calendars!",
  },
  {
    title:
      "Global Round to Include 4 Exciting Categories for Students Worldwide!",
    date: "June 1-4",
    content:
      "The upcoming Global Round in LA will feature FOUR exciting categories designed to challenge and inspire students from across the globe. Prepare for a once-in-a-lifetime experience that will test your skills and creativity!",
  },
  {
    title: "Stay Tuned! More Upcoming News Revealed Soon...",
    date: "",
    content: "Stay tuned and keep watching for more exciting updates! ",
  },
];

function NewsList() {
  return (
    <NewsContainer>
      {newsData.map((article, index) => (
        <Article key={index} {...article} />
      ))}
    </NewsContainer>
  );
}

export default NewsList;
