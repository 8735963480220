import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const IconBox = ({ data, classOption }) => {
  return (
    <div
      style={{
        backgroundColor: "#52668c",
        color: "white",
        height: "440px",
        padding: "10px",
      }}
      className={`icon-box text-center ${classOption}`}
    >
      <div className="icon">
        <img src={data.icon} alt="" />
      </div>
      <div className="content">
        <h3
          className="title"
          style={{
            color: "white",
          }}
        >
          {data.title}
        </h3>
        <div className="desc">
          <p
            style={{
              color: "white",
            }}
          >
            {data.desc}
          </p>
        </div>
        <Link
          to={data.link}
          className="link"
          style={{
            color: "#c5d7eb",
            fontWeight: "bold",
            textDecoration: "underline",
            position: "absolute",
            bottom: "30px",
            left: "50%",
            transform: `translate(-50%,-50%)`,
          }}
        >
          {data.pageLink}
        </Link>
      </div>
    </div>
  );
};

IconBox.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

IconBox.defaultProps = {
  classOption: "icon-box text-center",
};

export default IconBox;
