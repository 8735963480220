import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AOS from "aos";
import IconBoxData from "../../data/iconBox/icon-box.json";
import IconBox from "../../components/IconBox/IconBox.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const ServiceIconBox = ({ classOption }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div
      className={`section section-padding-t90-b100 ${classOption}`}
      data-aos="fade-up"
    >
      <div className="container" id="categories">
        <SectionTitle
          title="We create unique competitions for you."
          subTitle="Select to get more info"
        />

        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 mb-n6">
          {IconBoxData &&
            IconBoxData.slice(0, 4).map((single, key) => {
              return (
                <div key={key} className="col mb-6">
                  <IconBox classOption="box-border" data={single} key={key} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

ServiceIconBox.propTypes = {
  classOption: PropTypes.string,
};
ServiceIconBox.defaultProps = {
  classOption: "section section-padding-t90-b100",
};

export default ServiceIconBox;
