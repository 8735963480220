import { useEffect, useRef, useState } from "react";
import { envData } from "../../envs";
import Button from "@mui/material/Button";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { isMobile } from "react-device-detect";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { FaFilePdf } from "react-icons/fa"; // Import PDF icon

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#001F3F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

import axios from "axios";
function ArtStudentsTable() {
  const [artStudents, setArtStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [singleArtProjectData, setSingleArtProjectData] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [summary, setSummary] = useState("");
  const [isFirstCompleted, setIsFirstCompleted] = useState(false); // State to track completion of the first async function

  const [page, setPage] = useState(0);
  function filterPaidArtExams(data) {
    return data.filter(
      (person) =>
        Array.isArray(person.exams) &&
        person.exams.some((exam) => exam.examName === "Art" && exam.isPayed)
    );
  }
  const getStudentInfo = async () => {
    const token = localStorage.getItem("token");
    try {
      const request1 = await axios.get(`${envData.baseApiUrl}/v1/students`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const artFiltered = filterPaidArtExams(request1.data);

      setArtStudents(artFiltered);
      const request2 = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/private/list?email=${artFiltered[page].email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSingleArtProjectData(request2.data);

      setFileNames(request2.data?.msg?.files);
      setSummary(request2.data?.msg?.summary);
      setIsFirstCompleted(!isFirstCompleted);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  function filterObjectProperties(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) => typeof value === "object" && value !== null
      )
    );
  }
  const getArtProjects = async () => {
    const token = localStorage.getItem("token");
    try {
      const request2 = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/private/list?email=${artStudents[page]?.email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSingleArtProjectData(request2.data);
      setFileNames(request2.data?.msg?.files);
      setSummary(request2.data?.msg?.summary);
      setIsFirstCompleted(!isFirstCompleted);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const downloadFiles = async () => {
    const token = localStorage.getItem("token");
    const newFileUrls = [];
    // if (!fileNames.length) {
    //   return;
    // }
    for (let file of fileNames) {
      try {
        const response = await fetch(
          `${envData.baseApiUrl}/v1/students/student/private/download?email=${
            artStudents[page].email
          }&file=${encodeURIComponent(file)}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // if (!response.ok) throw new Error(`Failed to fetch ${file}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        newFileUrls.push({ name: file, url: blobUrl });
      } catch (error) {
        console.error("Error fetching file:", file, error);
      }
    }
    setFileUrls(newFileUrls); // Update state with all fetched files
  };
  useEffect(() => {
    getStudentInfo();
  }, []);
  const curr = useRef(false);
  useEffect(() => {
    if (!curr.current) {
      curr.current = true;
      return;
    }
    getArtProjects();
  }, [page]);
  useEffect(() => {
    // if (isFirstCompleted) {
    downloadFiles();
    // }
  }, [isFirstCompleted]);
  const handleNextPage = () => {
    if (page < artStudents.length - 1) {
      setPage(page + 1);
    }
  };
  const handlePrevPage = () => {
    if (page) {
      setPage(page - 1);
    }
  };
  const filteredAfterSearch = artStudents.filter((student) =>
    student?.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        width: "90%",
        flexDirection: "column",
      }}
    >
      {singleArtProjectData?.msg?.files.length > 0 ? (
        <div style={{ color: "black" }}>
          <h4
            style={{ marginBottom: "10px" }}
          >{`${artStudents[page].firstName} ${artStudents[page].lastName}`}</h4>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {fileUrls.map(({ name, url }, index) => {
              const isPDF = name.endsWith(".pdf");

              return isPDF ? (
                <Box key={index} style={{ color: "#000080", margin: "20px" }}>
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pdf-link"
                  >
                    <FaFilePdf className="pdf-icon" />{" "}
                    {name.toUpperCase().slice(0, 12)}
                  </a>
                </Box>
              ) : (
                <Card sx={{ maxWidth: 345 }} key={index}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: "teal" }} aria-label="recipe">
                        A
                      </Avatar>
                    }
                    title={name ? name.split(".")[0] : ""}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image={url}
                    alt={name.slice(0, 10)}
                  />

                  <CardContent>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: 2,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          {summary}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
      ) : singleArtProjectData?.msg?.files.length === 0 ? (
        <div style={{ color: "black" }}>
          <h4
            style={{ marginBottom: "10px" }}
          >{`${artStudents[page].firstName} ${artStudents[page].lastName}`}</h4>
          No data for this student, Click next button
        </div>
      ) : (
        <>Loading</>
      )}
      <div>
        <br />
        <br />
        <br />
      </div>
      {/* {JSON.stringify(singleArtProjectData)} */}
      <div style={{ display: "flex" }}>
        <Button
          variant="contained"
          onClick={handlePrevPage}
          style={{ marginRight: "10px" }}
          disabled={!page}
        >
          prev
        </Button>
        <Button variant="contained" onClick={handleNextPage}>
          {artStudents.length - 1 === page ? "End of data" : "next"}
        </Button>
      </div>
      <input
        style={{ width: isMobile ? "90%" : "50%", margin: "10px 0px" }}
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "5px" }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ border: "3px solid red" }}>
              <TableRow style={{ border: "3px solid red" }}>
                <StyledTableCell
                  style={{
                    minWidth: 30,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  #
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    minWidth: 170,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Full Name
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    minWidth: 130,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Country
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 140,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Email
                </StyledTableCell>

                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 20,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Grade
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 140,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  School Name
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 100,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  DOB
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{
                    minWidth: 110,
                    fontWeight: "bold",
                    borderBottom: "2px solid grey",
                  }}
                >
                  Phone
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAfterSearch.map((row, ind) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.email}
                  >
                    <StyledTableCell>{ind + 1}</StyledTableCell>
                    <StyledTableCell>
                      {row.firstName + " " + row.lastName}
                    </StyledTableCell>

                    <StyledTableCell>{row.countryName}</StyledTableCell>
                    <StyledTableCell align="right">{row.email}</StyledTableCell>

                    <StyledTableCell align="right">{row.grade}</StyledTableCell>

                    <StyledTableCell align="right">
                      {row.schoolName}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.dob}</StyledTableCell>
                    <StyledTableCell align="right">{row.phone}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ArtStudentsTable;
